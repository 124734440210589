import clsx from 'clsx'
import {
  Dialog,
  DialogTrigger,
  Popover as RACPopover,
  type PopoverProps,
} from 'react-aria-components'
import styles from './popover.module.css'

interface Props extends Omit<PopoverProps, 'children'> {
  children: React.ReactNode
}

export function Popover({ className, children, ...props }: Props) {
  return (
    <RACPopover
      className={clsx(className, styles.popover, 'text-2')}
      {...props}
    >
      {/* <OverlayArrow className={styles.popoverArrow}>
        <svg width={12} height={12} viewBox="0 0 12 12">
          <path d="M0 0 L6 6 L12 0" />
        </svg>
      </OverlayArrow> */}
      <Dialog aria-label=" ">{children}</Dialog>
    </RACPopover>
  )
}

export function PopoverPlain({ className, children, ...props }: Props) {
  return (
    <RACPopover
      className={clsx(className, styles.popover, 'text-2')}
      {...props}
    >
      {children}
    </RACPopover>
  )
}

export const PopoverTrigger = DialogTrigger
