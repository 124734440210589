import clsx from 'clsx'
import React from 'react'
import type { LabelProps } from 'react-aria-components'
import { Label as RACLabel } from 'react-aria-components'
import styles from './label.module.css'

export function Label({ className, ...props }: LabelProps) {
  return (
    <RACLabel className={clsx(styles.label, 'text-1', className)} {...props} />
  )
}

export function FakeLabel({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string | undefined
}) {
  return (
    <div aria-hidden="true" className={clsx(styles.label, 'text-1', className)}>
      {children}
    </div>
  )
}
