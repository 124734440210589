import clsx from 'clsx'
import type { ListBoxItemProps, ListBoxProps } from 'react-aria-components'
import { ListBox, ListBoxItem } from 'react-aria-components'
import styles from './list-box.module.css'

function _ListBox<T extends object>({ className, ...props }: ListBoxProps<T>) {
  return <ListBox {...props} className={clsx(className, styles.listBox)} />
}

function _ListBoxItem({ className, ...props }: ListBoxItemProps) {
  return (
    <ListBoxItem {...props} className={clsx(className, styles.listBoxItem)} />
  )
}

export { _ListBox as ListBox, _ListBoxItem as ListBoxItem }
